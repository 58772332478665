import React, { useEffect, useState } from 'react';
import { Container, Typography, Button, Box, Modal, Alert, LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/authService';

const Account = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [nextBillingTime, setNextBillingTime] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    const getAccountDetails = async () => {
      setLoading(true);
      fetch(
        `https://server.nepeto.com/aniseller_get_sub_details/${getUser()}/`,
        {
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setActive(data.active);
          setNextBillingTime(data.next_billing_time);
        })
        .catch(() => {
        });

      setLoading(false);
    };
    getAccountDetails();
  }, []);

  const handleProceed = async () => {
    fetch(
      `https://server.nepeto.com/nepeto-ra-cancel/${getUser()}/`,
      {
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          navigate('/');
        }
      })
      .catch(() => {
      });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4, }}>
        <Typography variant="h5" component="h2" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#7e3e1b', }}>
          Hi {getUser()} !
        </Typography>
      </Box>

      {loading ? (
        <div style={{ color: '#7e3e1b', }}>
          <LinearProgress sx={{ marginTop: '16px' }} color={'inherit'} />
        </div>
      ) : (
        <Box sx={{ mt: 6, textAlign: 'center' }}>
          {active ? (
            <Alert severity="success">Your account is Active</Alert>
          ) : (
            <Alert severity="error" action={
              <Button
                variant="outlined"
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#769358',
                  borderRadius: '20px',
                  backgroundColor: 'transparent',
                  border: '1px solid #769358',
                }}
                size="small"
                onClick={() => {
                  window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=101604&page-template=13366&language=en&currency=USD&billing-email=${getUser()}`;
                }}>
                Activate Account
              </Button>
            }>Your account is InActive</Alert>
          )}
          {active &&
            <Typography variant="body1" color="text.secondary" style={{ fontFamily: 'RobotoSlab', marginTop: 15 }}>
              Your next billing date is: {nextBillingTime ? new Date(nextBillingTime).toLocaleDateString() : 'N/A'}
            </Typography>
          }

          <Button
            variant="contained"
            disabled={!active}
            onClick={async () => {
              setOpenConfirmModal(true);
            }}
            style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3',
              marginTop: 15
            }}
          >
            Remove Subscription
          </Button>
        </Box>
      )}


      <Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Are you sure you want to proceed?
          </Typography>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenConfirmModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              No
            </Button>
            <Button variant="contained" onClick={handleProceed} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              Yes
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container >
  );
};

export default Account;