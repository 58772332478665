import React, { useState } from 'react';
import {
  Typography,
  Container,
  Box,
  TextField,
  Button,
  Paper,
  ThemeProvider,
  createTheme,
  Checkbox,
  FormControlLabel,
  Link,
  Modal,
  InputAdornment,
  IconButton,
  styled
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../../utils/authService';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { sha256 } from '../../utils/helpers';

const theme = createTheme({
  palette: {
    primary: {
      main: '#7e3e1b',
    },
    background: {
      default: '#FCF8F3',
    },
  },
  typography: {
    fontFamily: 'RobotoSlab',
  },
});

const VintageTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#7e3e1b',
    },
    '&:hover fieldset': {
      borderColor: '#a67b45',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#7e3e1b',
    },
  },
  '& .MuiInputLabel-root': {
    color: '#7e3e1b',
  },
});

const CustomButton = ({ children, ...props }) => (
  <Button
    variant="contained"
    style={{
      textTransform: 'none',
      fontFamily: 'RobotoSlab',
      color: '#FCF8F3',
      borderRadius: '20px',
      backgroundColor: '#7e3e1b',
      border: '1px solid #FCF8F3',
    }}
    {...props}
  >
    {children}
  </Button>
);

const FlipCard = styled(Paper)(({ theme, flipped }) => ({
  perspective: '1000px',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
  position: 'relative',
  width: '80%',
  height: flipped ? '50vh' : '36vh',
  transform: flipped ? 'rotateY(180deg)' : 'rotateY(0deg)',
  margin: 'auto',
}));

const CardSide = styled(Box)(({ theme, side }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#FCF8F3',
  border: '2px solid #7e3e1b',
  borderRadius: '10px',
  boxShadow: '1px 1px 15px 10px lightgray',
  transform: side === 'back' ? 'rotateY(180deg)' : 'rotateY(0deg)',
  overflowY: 'auto',
}));

const SignInForm = ({ handleSubmit, setEmail, setPassword, flipCard, handleForgotPassword }) => (
  <Box component="div" sx={{ mt: 1 }}>
    <Typography variant="h4" align="center" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
      Sign In
    </Typography>
    <VintageTextField
      margin="normal"
      required
      fullWidth
      id="emailSignIn"
      label="Email Address"
      name="email"
      autoComplete="email"
      autoFocus
      onChange={(e) => setEmail(e.target.value)}
    />
    <VintageTextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="passwordSignIn"
      autoComplete="current-password"
      onChange={(e) => setPassword(e.target.value)}
    />
    <CustomButton fullWidth sx={{ mt: 3, mb: 2 }} onClick={handleSubmit}>
      Sign In
    </CustomButton>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
      <Link
        component="button"
        variant="body2"
        onClick={handleForgotPassword}
        sx={{ color: '#7e3e1b' }}
      >
        Forgot password?
      </Link>
      <Typography variant="body2" sx={{ color: '#7e3e1b', cursor: 'pointer' }} onClick={flipCard}>
        Don't have an account? Sign up
      </Typography>
    </Box>
  </Box>
);

const SignUpForm = ({ handleSubmit, setEmail, setPassword, setConfirmPassword, setAgreeToTerms, flipCard }) => (
  <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
    <Typography variant="h4" align="center" color="primary" gutterBottom sx={{ fontWeight: 'bold' }}>
      Create an Account
    </Typography>
    <VintageTextField
      margin="normal"
      required
      fullWidth
      id="emailSignUp"
      label="Email Address"
      name="email"
      autoComplete="email"
      onChange={(e) => setEmail(e.target.value)}
    />
    <VintageTextField
      margin="normal"
      required
      fullWidth
      name="password"
      label="Password"
      type="password"
      id="passwordSignUp"
      autoComplete="new-password"
      onChange={(e) => setPassword(e.target.value)}
    />
    <VintageTextField
      margin="normal"
      required
      fullWidth
      name="confirmPassword"
      label="Confirm Password"
      type="password"
      id="confirmPassword"
      autoComplete="new-password"
      onChange={(e) => setConfirmPassword(e.target.value)}
    />
    <FormControlLabel
      control={<Checkbox onChange={(e) => setAgreeToTerms(e.target.checked)} />}
      label={
        <Typography variant="body2">
          I agree to the <Link href="#" sx={{ color: '#7e3e1b' }}>Terms of Service</Link> and <Link href="#" sx={{ color: '#7e3e1b' }}>Privacy Policy</Link>
        </Typography>
      }
      sx={{ mt: 2 }}
    />
    <CustomButton type="submit" fullWidth sx={{ mt: 3, mb: 2 }}>
      Sign Up
    </CustomButton>
    <Typography variant="body2" align="center" sx={{ mt: 2, color: '#7e3e1b', cursor: 'pointer' }} onClick={flipCard}>
      Already have an account? Sign in
    </Typography>
  </Box>
);

const ResetPasswordModal = ({ open, handleClose, handleResetPassword, veriCode, setVeriCode, setModalMsg, setOpenModal }) => {
  const [confirmationCode, setConfirmationCode] = useState(['', '', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);

  const handleCodeChange = (index, value) => {
    if (value.length <= 1 && /^\d*$/.test(value)) {
      const newCode = [...confirmationCode];
      newCode[index] = value;
      setConfirmationCode(newCode);

      // Move focus to the next input
      if (value && index < 4) {
        document.getElementById(`code-${index + 1}`).focus();
      }
    }
  };

  const verifyCode = async () => {
    const code = confirmationCode.join('');

    if (await sha256(code) === veriCode) {
      setVeriCode("GOOD");

      setIsCodeVerified(true);
    } else {
      setModalMsg("Invalid code. Please try again.");
      setOpenModal(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    handleResetPassword(await sha256(newPassword));
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '26vw',
          bgcolor: '#FCF8F3',
          borderRadius: 5,
          border: '4px solid rgba(126, 62, 27, 0.5)',
          boxShadow: 24,
          p: 4,
          outline: 'none',
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
          Reset Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          {!isCodeVerified ? (
            <>
              <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center' }}>
                Please enter your code from email here:
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                {confirmationCode.map((digit, index) => (
                  <TextField
                    key={index}
                    id={`code-${index}`}
                    value={digit}
                    onChange={(e) => handleCodeChange(index, e.target.value)}
                    variant="outlined"
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                    sx={{ width: '40px' }}
                  />
                ))}
              </Box>
              <Button onClick={verifyCode} fullWidth variant="contained" sx={{ mt: 2 }}>
                Verify Code
              </Button>
            </>
          ) : (
            <VintageTextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            {isCodeVerified && (
              <CustomButton type="submit">
                Reset Password
              </CustomButton>
            )}
            <Button variant="contained" onClick={handleClose} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const LoginSignUp = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [veriCode, setVeriCode] = useState('NOCODE');

  const [modalMsg, setModalMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailPattern = /^[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const logUrl = `https://server.nepeto.com/get_aniseller_user/${email}/${localStorage.getItem("tkk")}/`;
    const res = await fetch(logUrl);
    const res_data = await res.json();
    if (res_data.ok) {
      signIn(email);
      props.setIsAuth(true);
      navigate('/dashboard');
    } else {
      setModalMsg('Login failed');
      setOpenModal(true);
    }
    setLoading(false);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!email || !password) {
      setModalMsg("Please fill all fields!");
      setOpenModal(true);
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setModalMsg("Passwords don't match");
      setOpenModal(true);
      setLoading(false);
      return;
    }

    if (!agreeToTerms) {
      setModalMsg("Please agree to the Terms of Service and Privacy Policy");
      setOpenModal(true);
      setLoading(false);
      return;
    }

    const signUpUrl = `https://server.nepeto.com/aniseller_start/${email}/${await sha256(password)}/none/`;
    const res = await fetch(signUpUrl);
    const res_data = await res.json();
    if (res_data) {
      setModalMsg("Sign up successful! Please log in.");
      setOpenModal(true);
      props.setIsFlipped(false);
    } else {
      setModalMsg("Sign up failed");
      setOpenModal(true);
    }

    setLoading(false);
  };

  const sendVerificationEmail = async (email) => {
    if (!validateEmail(email)) {
      setModalMsg('Invalid email');
      setOpenModal(true);
      return;
    }
    const logUrl = `https://server.nepeto.com/aniseller_verify_user/${email}/`; // https://server.nepeto.com
    const res = await fetch(logUrl);
    const res_data = await res.json();
    if (res_data) {
      setVeriCode(res_data)
    } else {
      setModalMsg("User not exists. Please sign up or contact support for help.")
      setOpenModal(true);
    }
  };

  const handleForgotPassword = () => {
    if (!validateEmail(email)) {
      setModalMsg("Please enter valid email!");
      setOpenModal(true);
      setLoading(false);
      return;
    }
    if (veriCode !== "NOCODE") {
      setModalMsg("Please Refresh page");
      setOpenModal(true);
      setLoading(false);
      return;
    }
    sendVerificationEmail(email);

    setOpenResetModal(true);
  };

  const handleResetPassword = async (newPassword) => {
    const logUrl = `https://server.nepeto.com/aniseller_reset_password/${email}/${newPassword}/`;
    const res = await fetch(logUrl);
    const res_data = await res.json();
    if (!res_data) {
      setModalMsg("User not exists. Please sign up or contact support for help.")
      setOpenModal(true);
    }
    setOpenResetModal(false);
  };

  const flipCard = () => {
    props.setIsFlipped(!props.isFlipped);
  };

  if (props.isAuth) {
    navigate('/dashboard');
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            minHeight: '80vh',
            display: 'flex',
            alignItems: 'center',
            py: 4,
            // backgroundImage: 'url("/assets/logo512 .png")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Container maxWidth="sm">
            <FlipCard elevation={3} flipped={props.isFlipped}>
              <CardSide side="front">
                <SignInForm
                  handleSubmit={handleLogin}
                  setEmail={setEmail}
                  setPassword={async (value) => {
                    setPassword(value);
                    localStorage.setItem("tkk", await sha256(value));
                  }}
                  flipCard={flipCard}
                  handleForgotPassword={handleForgotPassword}
                />
              </CardSide>
              <CardSide side="back">
                <SignUpForm
                  handleSubmit={handleSignUp}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setConfirmPassword={setConfirmPassword}
                  setAgreeToTerms={setAgreeToTerms}
                  flipCard={flipCard}
                />
              </CardSide>
            </FlipCard>
          </Container>
        </Box>
      </ThemeProvider>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            {modalMsg}
          </Typography>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              OK
            </Button>
          </Box>
        </Box>
      </Modal>

      <ResetPasswordModal
        open={openResetModal}
        handleClose={() => setOpenResetModal(false)}
        handleResetPassword={handleResetPassword}
        veriCode={veriCode}
        setVeriCode={setVeriCode}
        setModalMsg={setModalMsg}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default LoginSignUp;