import React, { useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Button, Switch, Box, Grid, createTheme } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/authService';

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#7e3e1b',
    '&:hover': {
      backgroundColor: alpha('#7e3e1b', theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#7e3e1b',
  },
}));

const Pricing = (props) => {
  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);

  const handleToggle = () => {
    props.setIsYearly(!props.isYearly);
  };

  useEffect(() => {
    const checkAccess = async () => {
      fetch(
        `https://server.nepeto.com/aniseller_sub_check/${getUser()}/`, // https://server.nepeto.com
        {
          mode: "cors",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setSubscribed(data);
        })
        .catch(() => {
        });
    };
    checkAccess();
  }, []);

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4, }}>
        <Typography variant="h2" component="h1" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#7e3e1b', }}>
          Our Pricing Plans
        </Typography>
        <Typography variant="h5" component="h2" gutterBottom align="center" style={{ fontFamily: 'RobotoSlab', color: '#7e3e1b', }}>
          Choose the plan that fits your needs
        </Typography>
      </Box>

      <Grid container justifyContent="center">
        <Grid item style={{display: "flex", flexDirection: "row"}}>
          <Card style={{ maxWidth: 350, backgroundColor: '#FCF8F3', margin: 'auto', textAlign: 'center', borderRadius: '15px', boxShadow: '0 4px 20px rgba(0,0,0,0.1)', }}>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom style={{ fontFamily: 'RobotoSlab', }}>
                Advanced
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
                <Typography style={{ fontFamily: 'RobotoSlab', }}>Monthly</Typography>
                <CustomSwitch checked={props.isYearly} onChange={handleToggle} style={{ color: '#7e3e1b', }} />
                <Typography style={{ fontFamily: 'RobotoSlab', }}>Yearly</Typography>
              </Box>
              
              {props.isYearly && <Typography style={{ fontFamily: 'RobotoSlab', fontSize: 14, }}>* billed yearly</Typography>}
              <Typography style={{ color: '#7e3e1b', fontFamily: 'RobotoSlab', fontSize: '2.5rem', fontWeight: 'bold', marginBottom: 5, }}>
                ${props.isYearly ? 62 : 78}<Typography component="span" variant="h6">/month</Typography>
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> 100 scans / month
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> Priority support
              </Typography>
              <Typography style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', }}>
                <CheckCircleIcon style={{ color: '#7e3e1b', }} sx={{ mr: 1 }} /> Advanced analytics
              </Typography>
              <Button
                variant="contained"
                disabled={subscribed}
                onClick={() => {
                  props.setIsFlipped(true);
                  window.location.href = props.isYearly ?
                  `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=101604&page-template=13366&language=en&currency=USD&billing-email=${getUser()}` : 
                  `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=101604&page-template=13366&language=en&currency=USD&billing-email=${getUser()}`
                }}
                style={{
                  textTransform: 'none',
                  fontFamily: 'RobotoSlab',
                  color: '#FCF8F3',
                  borderRadius: '20px',
                  backgroundColor: subscribed ? '#9e9e9e' : '#7e3e1b',
                  border: '1px solid #FCF8F3',
                  marginTop: 15
                }}
              >
                {subscribed ? 'Current Plan' : 'Get Started'}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="body1" gutterBottomstyle={{ fontFamily: 'RobotoSlab', }}>
          All plans come with a 30-day money-back guarantee.
        </Typography>
        <Typography variant="body2" color="text.secondary" style={{ fontFamily: 'RobotoSlab', }}>
          Have questions? Contact our sales team for more information.
        </Typography>
      </Box>
    </Container>
  );
};

export default Pricing;