import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, CircularProgress, LinearProgress, Button } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../utils/authService';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ProductsTable from '../../components/ProductsTable';
import FilterBar from '../../components/FilterBar';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import ScansTable from './ScansTable';

// Create a vintage theme
const vintageTheme = createTheme({
  palette: {
    primary: {
      main: '#7e3e1b',
    },
    secondary: {
      main: '#d2a679',
    },
    background: {
      default: '#f5e6d3',
      paper: '#efe1c6',
    },
    text: {
      primary: '#4a3728',
      secondary: '#7e3e1b',
    },
  },
  typography: {
    fontFamily: 'RobotoSlab',
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 8px rgba(126, 62, 27, 0.2)',
          border: '1px solid #7e3e1b',
          borderRadius: '10px',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0 5px',
          // border: '2px solid #7e3e1b',
          borderRadius: '5px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
        head: {
          fontWeight: 'bold',
          color: '#7e3e1b',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            '& > th': {
              borderBottom: '2px solid #7e3e1b',
              fontSize: 'large',
            },
          },
          '&:nth-of-type(odd)': {
            backgroundColor: 'rgba(126, 62, 27, 0.05)',
          },
        },
      },
    },
  },
});

const Dashboard = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [scanData, setScanData] = useState([]);
  const [favoritesData, setFavoritesData] = useState([]);
  const [favorites, setFavorites] = useState(false);
  const [filters, setFilters] = useState({});
  const [sortASC, setSortASC] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const user = getUser();
        const tkk = localStorage.getItem('tkk');

        const result = await fetch(`https://server.nepeto.com/get_aniseller_user/${user}/${tkk}/`);
        const resData = await result.json();
        if (resData.ok) {
          setScanData(resData.data.recent_scans);
          let categoriesTemp = [];
          resData.data.favorites.forEach((product) => {
            categoriesTemp.push(product.category);
          });
          categoriesTemp = [...new Set(categoriesTemp)];

          setCategories(categoriesTemp)

          const uniqueProducts = new Map();
          resData.data.favorites.forEach((product) => {
            uniqueProducts.set(product.asin, product); // Use 'asin' as the key
          });

          // Convert back to an array of unique products
          const uniqueProductsArray = Array.from(uniqueProducts.values());

          setFavoritesData(uniqueProductsArray);
        }
      }
      catch {

      }
      setLoading(false);
    }
    fetchData();
  }, []);

  const filterData = () => {
    let tempData = [];
    favoritesData.forEach((product) => {
      if (product.category === category) {
        tempData.push(product);
      }
    })
    return tempData;
  }

  if (!props.isAuth) {
    navigate('/login');
  }

  return (
    <ThemeProvider theme={vintageTheme}>
      <Container maxWidth="md" sx={{ mt: 4, mb: 1, p: 3, borderRadius: 2 }}>
        <Typography variant="h4" gutterBottom align="center" color="primary" sx={{ mb: 4 }}>
          {favorites ? 'Favorites' : 'Dashboard'}
        </Typography>
        <Typography onClick={() => setFavorites(!favorites)} style={{ fontFamily: 'RobotoSlab', display: 'flex', alignItems: 'center', margin: '10px 10px 10px 20px', cursor: 'pointer', }}>
        {favorites ? <SpaceDashboardIcon /> : <FavoriteBorderIcon />}{favorites ? 'Show Dashboard' : 'Show Favorites'}
        </Typography>
      </Container>

      {loading ? (
        <div style={{ color: '#7e3e1b', }}>
          <LinearProgress sx={{ marginTop: '16px' }} color={'inherit'} />
        </div>
      ) : (
        <>
          {favorites ? (
            <>
              <Typography variant="h5" align="center" color="#7e3e1b" gutterBottom>
                Search by Category&nbsp;{category !== "" && <Button onClick={() => setCategory("")}>Back</Button>}
              </Typography>
              
              
              {category === "" ? (
                <Container>
                  <Grid container spacing={4} sx={{ mt: 4 }}>
                    {categories.map((cat, index) => (
                      <Grid item xs={12} sm={6} md={4} key={index}>
                        <Button onClick={() => setCategory(cat)}>{cat}</Button>
                      </Grid>
                    ))}
                  </Grid>
                </Container>
              ) : (
                <>
                  <FilterBar
                    data={filterData()}
                    setFilters={setFilters}
                    filters={filters}
                    activePlanLevel={"Master Plan"}
                  />

                  <ProductsTable
                    data={filterData()}
                    setData={() => alert('setData')}
                    isMobile={false}
                    supplierType={"storefront"}
                    hideCredits={0}
                    showAdv={true}
                    isProductsLoading={false}
                    filters={filters}
                    setFilters={setFilters}
                    sortASC={sortASC}
                    setSortASC={setSortASC}
                    identifier={'Barcode'}
                    sellerId={''}
                    refreshToken={''}
                    vlookupObj={'vlookupObj'}
                  />
                </>
              )}
            </>
          ) : (
            <Container>
              <Grid container spacing={3} justifyContent="center" sx={{ mb: 4 }}>
                <Grid item >
                  <Card elevation={0}>
                    <CardContent>
                      {loading ? (
                        <Box style={{ display: 'flex', justifyContent: 'center', }}>
                          <CircularProgress style={{ color: '#7e3e1b' }} />
                        </Box>
                      ) : (
                        <Typography variant="h4" color="primary">{scanData ? scanData.reduce((total, item) => total + item.lineCount, 0) : 0}</Typography>
                      )}
                      <Typography variant="h6" component="div" color="secondary">
                        Items Scanned
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item>
                  <Card elevation={0}>
                    <CardContent>
                      {loading ? (
                        <Box style={{ display: 'flex', justifyContent: 'center', }}>
                          <CircularProgress style={{ color: '#7e3e1b' }} />
                        </Box>
                      ) : (
                        <Typography variant="h4" color="primary">{scanData ? scanData.length : 0}</Typography>
                      )}
                      <Typography variant="h6" component="div" color="secondary">
                        Files Uploaded
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <ScansTable scanData={scanData} />
            </Container>
          )}
        </>
      )}
    </ThemeProvider>
  );
};

export default Dashboard;