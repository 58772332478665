import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Toolbar, IconButton, Typography, Menu, Container, Avatar, Button, MenuItem, } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../fulllogo.svg';
import { signOut } from '../utils/authService';
import { useEffect, useState } from 'react';
import { AccountCircle } from '@mui/icons-material';

function Navbar(props) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navbarBtnStyle = {
    my: 3,
    mx: 1,
    borderRadius: 20,
    color: '#7e3e1b',
    display: 'block',
    fontFamily: 'RobotoSlab',
    fontSize: 18,
  }

  return (
    <AppBar position="static" sx={{ backgroundColor: '#FCF8F3', }} style={{ height: '10vh', }}>
      <Container maxWidth="xl" style={{ paddingRight: '10vw', paddingLeft: '10vw' }}>
        <Toolbar disableGutters>
          <img src={logo} alt={'Aniseller'} style={{ maxHeight: 50, cursor: 'pointer', }} onClick={() => navigate('/')} />

          {/* Folded */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="7e3e1b"
            >
              <MenuIcon color='#7e3e1b' />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              <MenuItem onClick={() => navigate('/csvscan')}>
                <Typography sx={{ textAlign: 'center', color: '#7e3e1b', fontFamily: 'RobotoSlab', }}>Scan</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/pricing')}>
                <Typography sx={{ textAlign: 'center', color: '#7e3e1b', fontFamily: 'RobotoSlab', }}>Pricing</Typography>
              </MenuItem>
              <MenuItem onClick={() => navigate('/blog')}>
                <Typography sx={{ textAlign: 'center', color: '#7e3e1b', fontFamily: 'RobotoSlab', }}>Blog</Typography>
              </MenuItem>
            </Menu>
          </Box>

          {/* Un-Folded */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', }}>
            <Button sx={{ ...navbarBtnStyle }} onClick={() => {
              if (!props.isAuth) {
                navigate('/login');
              } else {
                navigate('/dashboard');
              }
            }}>
              Dashboard
            </Button>
            <Button sx={{ ...navbarBtnStyle }} onClick={() => navigate('/csvscan')}>
              Scan
            </Button>
            <Button sx={{ ...navbarBtnStyle }} onClick={() => navigate('/pricing')}>
              Pricing
            </Button>

          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }, }}>
            {props.isAuth ? (
              <>
                <div>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    color="inherit"
                  >
                    <AccountCircle style={{ color: '#7e3e1b', fontSize: 40}}/>
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={() => {handleClose(); navigate('/account')}}>Account details</MenuItem>
                    <MenuItem onClick={() => {handleClose(); signOut(); props.setIsAuth(false); navigate('/')}}>Logout</MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <>
                <Button sx={{ ...navbarBtnStyle, fontSize: 14 }} onClick={() => {props.setIsFlipped(false); navigate('/login');}}>
                  LOGIN
                </Button>
                <Button sx={{ ...navbarBtnStyle, px: 4, color: '#FCF8F3', backgroundColor: '#7e3e1b', fontSize: 14 }} onClick={() => navigate('/login')} variant="contained">
                  Create an Account
                </Button>
              </>
            )}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Navbar;
