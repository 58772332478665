import { Box, Button, FormControl, Input, InputAdornment, InputLabel, Modal, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, tooltipClasses, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getUser } from "../../utils/authService";

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#7e3e1b',
    maxWidth: "10vw",
    border: '2px solid #7e3e1b',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#7e3e1b',
  },
}));

const ScansTable = (props) => {
  const [data, setData] = useState([]);
  const [identifier, setIdentifier] = useState("");
  const [filename, setFilename] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openFilenameModal, setOpenFilenameModal] = useState(false);
  const [asc, setAsc] = useState(false);
  const [row, setRow] = useState({});

  useEffect(() => {
    setData(props.scanData);
  }, []);

  const filterData = (iden) => {
    if (iden === "") {
      setData(props.scanData);
      return;
    }
    setIdentifier(iden);
    let tempData = [];
    props.scanData.forEach((product) => {
      if (product.identifier === iden) {
        tempData.push(product);
      }
    })
    setData(tempData);
  }

  const sortByDate = () => {
    setAsc(!asc);
    const sortedData = [...props.scanData].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (asc) {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });

    setData(sortedData);
  };

  const changeFilename = async (row) => {
    const user = getUser();
    await fetch(`https://server.nepeto.com/aniseller_update_filename/${user}/${row.date.replaceAll('/', '-')}/${filename}/`);
    window.location.reload();
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0} sx={{ bgcolor: 'background.paper', maxHeight: '50vh', }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="left" style={{ cursor: 'pointer', textDecoration: 'underline dotted', textUnderlineOffset: '4px' }} onClick={() => sortByDate()}>
                Date{asc ? <ArrowDownwardOutlinedIcon /> : <ArrowUpwardOutlinedIcon />}
              </TableCell>
              <TableCell align="left">Line Count</TableCell>
              <TableCell align="left" style={{ cursor: 'pointer', textDecoration: 'underline dotted', textUnderlineOffset: '4px' }}
                onClick={() => setOpenModal(true)}>Identifier{identifier !== "" ? ` - ${identifier}` : ""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.map((row, index) => (
              <TableRow key={index}>
                <TableCell scope="row" style={{ display: 'flex', alignItems: 'center' }}>
                  <img src={`../../assets/${row.fileName.endsWith('.csv') ? 'csv' : 'xlsx'}.png`} width={'35vw'} />&nbsp;{row.fileName}
                  <CostumTooltip
                    arrow
                    placement="top"
                    title={
                      <React.Fragment>
                        <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, zIndex: 99999 }}>Change File Name</Typography>
                      </React.Fragment>
                    }
                  >
                    <ModeEditOutlinedIcon style={{ cursor: 'pointer', }} onClick={() => { setRow(row); setOpenFilenameModal(true); }} />
                  </CostumTooltip>
                </TableCell>
                <TableCell align="left">{row.date}</TableCell>
                <TableCell align="left">{row.lineCount}</TableCell>
                <TableCell align="left">{row.identifier}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Select Identifier
          </Typography>

          {/* Identifier filter select buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => { setIdentifier("ASIN"); filterData("ASIN"); setOpenModal(false); }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: identifier === "ASIN" ? '#FCF8F3' : '#7e3e1b',
              borderRadius: '20px',
              backgroundColor: identifier === "ASIN" ? '#769358' : '#FCF8F3',
              border: `1px solid ${identifier === "ASIN" ? '#FCF8F3' : '#7e3e1b'}`
            }} >
              ASIN
            </Button>
            <Button variant="contained" onClick={() => { setIdentifier("Barcode"); filterData("Barcode"); setOpenModal(false); }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: identifier === "Barcode" ? '#FCF8F3' : '#7e3e1b',
              borderRadius: '20px',
              backgroundColor: identifier === "Barcode" ? '#769358' : '#FCF8F3',
              border: `1px solid ${identifier === "Barcode" ? '#FCF8F3' : '#7e3e1b'}`
            }} >
              Barcode (UPC/EAN)
            </Button>
            <Button variant="contained" onClick={() => { setIdentifier(""); filterData(""); setOpenModal(false); }} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: identifier === "" ? '#FCF8F3' : '#7e3e1b',
              borderRadius: '20px',
              backgroundColor: identifier === "" ? '#769358' : '#FCF8F3',
              border: `1px solid ${identifier === "" ? '#FCF8F3' : '#7e3e1b'}`
            }} >
              ALL
            </Button>
          </Box>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={openFilenameModal} onClose={() => setOpenFilenameModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Change File Name
          </Typography>

          {/* Filename Input */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <DescriptionOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField id="newFilename" label={row.fileName} variant="standard" value={filename} onChange={(e) => setFilename(e.target.value)}/>
            </Box>
          </Box>

          {/* Save & Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => {setOpenFilenameModal(false); changeFilename(row);}} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
            }} >
              Save
            </Button>
            <Button variant="contained" onClick={() => setOpenFilenameModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ScansTable;