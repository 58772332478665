import React, { useEffect, useState } from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';

function ModalForSettings(props) {
  const [modalSetting, setModalSetting] = useState(null);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');

  const handleClose = () => {
    props.setModalSetting("");
    setMaxValue(-1);
    setMinValue(-1);

    props.setOpen(false);
  };

  const handleSave = () => {
    props.setFilters((filters) => ({
      ...filters,
      [props.modalSetting]: [
        minValue !== "" ? parseFloat(minValue) : -1,
        maxValue !== "" ? parseFloat(maxValue) : -1,
      ],
    }));
    setMaxValue(-1);
    setMinValue(-1);
    props.setModalSetting("");
    
    handleClose();
  };

  useEffect(() => {
    if (props.filters && props.filters[[props.modalSetting]]) {
      setMinValue(props.filters[[props.modalSetting]][0]);
      setMaxValue(props.filters[[props.modalSetting]][1]);
    }
  }, [props.modalSetting, props.filters]);

  return (
    <>
      <Modal open={props.open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
          Set {props.modalSetting !== "top_bsr" ? "Min &" : ""} Max Value{props.modalSetting !== "top_bsr" ? "s" : ""} For{" "}
            <strong>
              {props.modalSetting.replaceAll("_", " ").toUpperCase()}
            </strong>
            {props.modalSetting.includes("offers") && " count"}
            {(props.modalSetting === "roi" || props.modalSetting === "top_bsr") && " (%)"}
          </Typography>

          {/* Min and Max input fields */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 2, justifyContent: 'space-around', }}>
            {props.modalSetting !== "top_bsr" && <TextField
              type='number'
              label="Enter Minimum"
              InputProps={{
                sx: {
                  fontFamily: 'RobotoSlab',
                  backgroundColor: '#F5F5F0',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'RobotoSlab',
                },
              }}
              variant="outlined"
              value={minValue !== -1 ? minValue : ""}
              onChange={(e) => {
                if (e.target.value < 0) {
                  setMinValue(0);
                } else {
                  setMinValue(e.target.value);
                }
              }}
            />}
            <TextField
              type='number'
              label={(props.modalSetting === "top_bsr" ? "Top %" : "Enter Maximum")}
              InputProps={{
                sx: {
                  fontFamily: 'RobotoSlab',
                  backgroundColor: '#F5F5F0',
                }
              }}
              InputLabelProps={{
                sx: {
                  fontFamily: 'RobotoSlab',
                },
              }}
              variant="outlined"
              value={maxValue !== -1 ? maxValue : ""}
              onChange={(e) => {
                if (props.modalSetting === "offers") {
                  setMaxValue(e.target.value > 20 ? 20 : e.target.value);
                } else {
                  if (e.target.value < 0) {
                    setMaxValue(0);
                  } else {
                    setMaxValue(e.target.value);
                  }
                }

                if(props.modalSetting === "top_bsr" && e.target.value > 50) {
                  alert("Please note that it is a top BSR in percentages, not a sales rank number. Please select the option 'Sales Rank #' in the previous dialog.")
                }
              }}
            />
          </Box>

          {/* Cancel and Save buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={handleClose} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
              }} >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSave} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#769358',
              border: '1px solid #FCF8F3'
              }} >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ModalForSettings;
