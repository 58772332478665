import { Box, Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, Menu, MenuItem, Modal, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import './Products.css';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { addToFavoritesDB, removeFromFavoritesDB } from "../utils/helpers";

const CostumTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCF8F3',
    color: '#7e3e1b',
    maxWidth: "10vw",
    border: '2px solid #7e3e1b',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#7e3e1b',
  },
});

const SortableTableHeader = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer;
    if (isLoading) {
      timer = setTimeout(() => setIsLoading(false), 500); // Ensure loading state for at least 500ms
    }
    return () => clearTimeout(timer);
  }, [isLoading]);

  const handleSort = async () => {
    setIsLoading(true);
    const newSortASC = !props.filters.sortBy?.[1];
    
    // Perform sorting operation in the next tick to allow loading state to be rendered
    setTimeout(() => {
      props.setSortASC(newSortASC);
      props.setFilters((prevFilters) => ({
        ...prevFilters,
        sortBy: [props.sortKey, newSortASC],
      }));
    }, 0);
  };

  return (
    <th className="sortColumn" onClick={handleSort}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          {props.columnName}
          {isLoading ? (
            <CircularProgress size={20} thickness={5} style={{ marginLeft: '8px', color: 'inherit', }}/>
          ) : (
            props.filters.sortBy?.[0] === props.sortKey && (
              props.filters.sortBy[1] ? (
                <ArrowUpwardOutlinedIcon style={{ fontSize: '100%', fontWeight: 'bold', marginLeft: '4px' }} />
              ) : (
                <ArrowDownwardOutlinedIcon style={{ fontSize: '100%', fontWeight: 'bold', marginLeft: '4px' }} />
              )
            )
          )}
        </div>
      </div>
    </th>
  );
};

function ProductsTable(props) {
  const categorySalesRankCount = { 'Climate Pledge Friendly': 1500000, 'Kindle Store': 6756566, 'Video Shorts': 127580, 'Apps & Games': 936100, 'Baby Products': 4172515, 'Digital Music': 68941656, 'Alexa Skills': 112529, 'Toys & Games': 9204843, 'Patio, Lawn & Garden': 22608266, 'Books': 102997339, 'Arts, Crafts & Sewing': 16296162, 'Software': 177161, 'Sports & Outdoors': 41416624, 'Gift Cards': 28409, 'Video Games': 1204647, 'Handmade Products': 3644844, 'Clothing, Shoes & Jewelry': 275101033, 'Office Products': 12266328, 'Grocery & Gourmet Food': 3869382, 'Tools & Home Improvement': 37997159, 'Movies & TV': 7740340, 'Musical Instruments': 2893522, 'Collectibles & Fine Art': 8802597, 'Appliances': 1565048, 'Pet Supplies': 8652837, 'Cell Phones & Accessories': 26169713, 'Baby': 4172515, 'Industrial & Scientific': 20388882, 'Everything Else': 8317014, 'CDs & Vinyl': 8366789, 'Beauty & Personal Care': 14890600, 'Home & Kitchen': 161989558, 'Electronics': 25856893, 'Automotive': 46255283, 'Magazine Subscriptions': 42578, 'Health & Household': 11049107, 'Audible Books & Originals': 743380, 'Kitchen & Dining': 37818304, "Amazon Devices": 1894, "Computers & Accessories": 14064999, "Camera & Photo Products": 4021127, "Collectible Coins": 210648, "Camera & Photo": 4021127 }

  const [page, setPage] = useState(1);
  const [prep, setPrep] = useState(localStorage.getItem("costPrep") || 0);
  const [barcodeColorMap, setBarcodeColorMap] = useState({});
  const [searchModal, setSearchModal] = useState({});
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [render, setRender] = useState(0);
  const [favorites, setFavorites] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const generateLightColor = () => `rgb(${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)},${Math.floor(Math.random() * 128 + 128)})`;

    setBarcodeColorMap((prevBarcodeColorMap) => {
      if (props.identifier === "Barcode") {
        const newBarcodeColorMap = { ...prevBarcodeColorMap };
        props.data.forEach(({ Barcode }) => {
          if (!newBarcodeColorMap[Barcode]) {
            newBarcodeColorMap[Barcode] = generateLightColor();
          }
        });
        return newBarcodeColorMap;
      } else {
        return {};
      }
    });
  }, [props.data]);

  useEffect(() => {
    setPage(1);
  }, [props.filters])

  const addToFavorites = (product) => {
    setFavorites(prev => {
      console.log("Adding to favorites:", product.asin);
      return {
        ...prev,
        [product.asin]: true
      };
    });

    addToFavoritesDB(product);
  };

  const removeFromFavorites = (product) => {
    setFavorites(prev => {
      console.log("Removing from favorites:", product.asin);
      const newFavorites = { ...prev };
      newFavorites[product.asin] = false;
      return newFavorites;
    });

    removeFromFavoritesDB(product.asin);
  };

  const hasPropertyIsFBM = () => {
    return props.filters && props.filters.hasOwnProperty("isFBM") && !props.filters.isFBM
  }

  return (
    <>
      <div className="products-table-container" id="anisellerProductTable">
        <table className="products-table" style={{ width: '94vw', }}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Category</th>
              <th>ASIN</th>
              <SortableTableHeader
                columnName={
                  <CostumTooltip
                    arrow
                    title={
                      <React.Fragment>
                        <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Sales Rank</li></ul></Typography>
                      </React.Fragment>
                    }
                  >
                    BSR<sub style={{ fontSize: 12, }}>(i)</sub>
                  </CostumTooltip>
                }
                sortKey="sales_rank"
                filters={props.filters}
                setSortASC={props.setSortASC}
                setFilters={props.setFilters}
              />
              <th>BSR Drops 30d|90d</th>
              <SortableTableHeader
                columnName={<>Price</>}
                sortKey="price"
                filters={props.filters}
                setSortASC={props.setSortASC}
                setFilters={props.setFilters}
              />
              <th>
                <CostumTooltip
                  arrow
                  title={
                    <React.Fragment>
                      <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Buy Box price with shipping (if available).</li><li>The lowest new price without shipping.</li></ul></Typography>
                    </React.Fragment>
                  }
                >
                  Price on<br />Amazon<sub style={{ fontSize: 12, }}>(i)</sub>
                </CostumTooltip>
              </th>
              <th>
                <CostumTooltip
                  arrow
                  title={
                    <React.Fragment>
                      <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Amazon Referral Fee + Fulfillment Cost (FBA)</li></ul></Typography>
                    </React.Fragment>
                  }
                >
                  Amazon<br />Fees<sub style={{ fontSize: 12, }}>(i)</sub>
                </CostumTooltip>
              </th>
              <th>Amount of sellers</th>
              <th>
                <CostumTooltip
                  arrow
                  title={
                    <React.Fragment>
                      <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, textAlign: 'left', }}><ul><li>Input any additional costs, such as prep cost, FBM, and others.</li></ul></Typography>
                    </React.Fragment>
                  }
                >
                  Costs<sub style={{ fontSize: 12, }}>(i)</sub>
                </CostumTooltip>
              </th>
              <SortableTableHeader
                columnName={<>Profit</>}
                sortKey="profit"
                filters={props.filters}
                setSortASC={props.setSortASC}
                setFilters={props.setFilters}
              />
              <SortableTableHeader
                columnName={<>ROI</>}
                sortKey="roi"
                filters={props.filters}
                setSortASC={props.setSortASC}
                setFilters={props.setFilters}
              />
              <th>Estimated<br />Sales/mo</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              (props.data.length === 0 || props.data.slice((page - 1) * 10, page * 10).length === 0) &&
              props.isProductsLoading === false &&
              <td style={{ textAlign: "center" }} colSpan={14}>No products.{props.data.length === 0 && " No scan credit used."}</td>
            }

            {props.data.slice((page - 1) * 10, page * 10).map((product, index) => {
              return (
                <>
                  <tr key={index} style={{ height: '15vh', }} render={render}>
                    <td className="data-row" style={{ width: '16vw' }}>
                      <div style={{ display: "flex" }}>
                        <div className="amazonImage">
                          <p onClick={() => { window.open(`https://amazon.com/dp/${product.asin}?th=1&psc=1`); }} style={{ textAlign: "center", cursor: "pointer", margin: 'auto', }}>Amazon</p>
                          <img
                            alt="product_image"
                            className="hoverImageTable"
                            loading="lazy"
                            src={product.image}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              borderRadius: "1vw",
                              // border: "5px solid rgba(126, 62, 27, 0.5)",
                              height: "4.5vw",
                              width: "4.5vw",
                              objectFit: "scale-down",
                              position: "relative",
                              zIndex: "5"
                            }}
                            onClick={() => {
                              window.open(`https://amazon.com/dp/${product.asin}?th=1&psc=1`);
                            }}
                          />
                        </div>

                        <span style={{ overflow: "hidden", textOverflow: "ellipsis", width: "10vw", height: "10vh", padding: "9px", textAlign: "center", margin: "auto", fontSize: "77.5%", }}>
                          {product.title && product.title !== null && product.title.split(" | At")[0].slice(0, 20)}...{product.title.split(" | At")[0].slice(-25)}
                          {product.title && product.title !== null && <CostumTooltip
                            arrow
                            placement="top-end"
                            title={
                              <React.Fragment>
                                <Typography color="#7e3e1b" style={{ fontWeight: 'bold', fontSize: 12, zIndex: 99999 }}>{product.title.split(" | At")[0]}</Typography>
                              </React.Fragment>
                            }
                          >
                            <InfoIcon style={{ cursor: "default", fontSize: '75%' }} />
                          </CostumTooltip>}
                          <br />
                          <span
                            style={{
                              padding: "0% .8%",
                              borderRadius: "30px",
                              color: "black",
                              backgroundColor: barcodeColorMap[product['Barcode']],
                              position: "absolute",
                              marginLeft: "-4.1%",
                              zIndex: "8",
                            }}>UPC{props.identifier !== "Barcode" ? "s" : ""}: {props.identifier === "Barcode" ? product['Barcode'].replace("'", "") : (product['upcs'].substring(0, product['upcs'].indexOf(',') !== -1 ? product['upcs'].indexOf(',') : product['upcs'].length) + "...")}</span>
                          {product.source && <span style={{ marginTop: "1.2%", padding: "0% .8%", borderRadius: "30px", color: "black", backgroundColor: 'lightgray', position: "absolute", marginLeft: "-4.1%", zIndex: "8" }}>Source: <a href={product.source} target="_blank" rel="noreferrer" style={{ textDecoration: "underline" }}>{product.source.split("www.")[1].substring(0, 10)}...</a></span>}
                        </span>

                        {product.csv_image && <div className="sourceImage">
                          <p style={{ textAlign: "center", maxWidth: "4.5vw", cursor: "pointer", margin: 'auto', }}>CSV</p>
                          <img
                            alt="product_image"
                            className="hoverImageTable"
                            loading="lazy"
                            src={product.csv_image ? product.csv_image : "/assets/noimage.png"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = "/assets/noimage.png";
                            }}
                            style={{
                              borderRadius: "1vw",
                              border: "5px solid rgba(126, 62, 27, 0.5)",
                              height: "4.5vw",
                              width: "4.5vw",
                              objectFit: "scale-down",
                              position: "relative",
                              zIndex: "5"
                            }}
                          />
                        </div>}
                      </div>
                    </td>
                    <td id="categoryTt" className="data-row" style={{ width: '7vw', maxWidth: '8vw' }}>
                      {product.category}
                    </td>
                    <td id="asinTt" className="data-row" style={{ width: '7vw', maxWidth: '8vw' }}>
                      <a
                        href={`https://www.amazon.com/dp/${product.asin}/`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "underline", color: "#7e3e1b", fontSize: 16 }}
                      >
                        {product.asin}
                      </a>
                      &nbsp;
                      <ContentCopyIcon
                        style={{ cursor: "copy", marginTop: "-.6%", fontSize: "75%" }}
                        onClick={(e) => {
                          navigator.clipboard.writeText(product.asin.split("/")[product.asin.split("/").length - 1]);
                        }}
                      />
                    </td>
                    <td id="bsrTt" className="data-row" style={{ minWidth: "5.6vw", color: !categorySalesRankCount[product.category] && "red" }}>
                      #{product.sales_rank}
                      {categorySalesRankCount[product.category] && (() => {
                        const salesRankPercentage = (product.sales_rank / categorySalesRankCount[product.category]) * 100;
                        return (
                          <>
                            <br />
                            <span style={{ fontSize: "83%" }}>
                              Top {salesRankPercentage < 0.01 ? "<0.01" : salesRankPercentage.toFixed(salesRankPercentage < 0.1 ? 2 : 1)}%
                            </span>
                          </>
                        );
                      })()}
                      <br />
                      <span style={{ fontSize: "70%" }}>
                        30d: #{product.bsr_avg30}
                      </span>
                    </td>
                    <td id="bsrDropsTt" className="data-row" style={{ width: '5.6vw', maxWidth: '5.6vw' }}>
                      {product.sales_rank_drops_30_days} | {product.sales_rank_drops_90_days}
                    </td>
                    <td id="priceTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
                      <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor={`${index}-price`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Price</InputLabel>
                        <Input
                          id={`${index}-price`}
                          size="small"
                          style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
                          placeholder={product.price}
                          startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
                          onChange={(e) => {
                            product.price = parseFloat(e.target.value);
                            setRender(rnd => rnd + 1)
                          }}
                        />
                      </FormControl>
                    </td>
                    <td id="azPriceTt" className="data-row" style={{ width: "4.5vw", maxWidth: "4.5vw", }}>
                      ${product.az_price.toFixed(2)}
                      {product.used_bb &&
                        <>
                          <br />
                          <span style={{ borderRadius: "30px", backgroundColor: "lightgreen", padding: "4% 10%", fontSize: "80%" }}>BuyBox</span>
                        </>
                      }
                      <br />
                      <span style={{ fontSize: "80%" }}>30d: ${product.az_price_avg30.toFixed(1)}</span>
                    </td>
                    <td id="azFeesTt" className="data-row">
                      {product.ref_fee_perc === 0 || product.fba_fee === 0 ? 
                        "N/A"
                      :
                        "-$" + ((product.ref_fee_perc * product.az_price) + (hasPropertyIsFBM() && product.fba_fee)).toFixed(2)}
                    </td>
                    <td id="azOffersTt" className="data-row" style={{ width: '5vw', justifyContent: 'center' }}>
                      {product.total_offers_count}<sub>sellers</sub>
                      {product.az_sells_it &&
                        <>
                          <br />
                          <span style={{ borderRadius: "30px", backgroundColor: "orange", padding: "4% 10%", fontSize: "80%" }}>AMZ</span>
                        </>
                      }
                    </td>
                    <td id="costTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
                      <FormControl sx={{ m: 1 }} variant="standard">
                        <InputLabel htmlFor={`${index}-cost`} style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>Cost</InputLabel>
                        <Input
                          id={`${index}-cost`}
                          type="number"
                          size="small"
                          style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}
                          value={prep}
                          inputProps={{ min: 0, step: 0.25 }}
                          startAdornment={<InputAdornment position="start" style={{ color: "#7e3e1b", fontFamily: "RobotoSlab" }}>$</InputAdornment>}
                          onChange={(e) => { setPrep(e.target.value); localStorage.setItem("costPrep", e.target.value) }}
                          onBlur={(e) => e.target.value === "" && setPrep(0)}
                        />
                      </FormControl>
                    </td>
                    <td id="profitTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
                      {(() => {
                        const profit = product.az_price - product.price - (product.ref_fee_perc * product.az_price) - (hasPropertyIsFBM() && product.fba_fee) - parseFloat(prep);

                        return (
                          <p style={{ color: profit < 0 ? '#B33B3B' : '#769358' }}>
                            {profit < 0 ? `-$${(profit * -1).toFixed(2)}` : `+$${profit.toFixed(2)}`}
                          </p>
                        );
                      })()}
                    </td>
                    <td id="roiTt" className="data-row" style={{ width: "6vw", maxWidth: "6vw" }}>
                      {(() => {
                        const profit = product.az_price - product.price - (product.ref_fee_perc * product.az_price) - (hasPropertyIsFBM() && product.fba_fee) - parseFloat(prep);
                        const roi = (profit / (product.price + parseFloat(prep))) * 100;

                        return (
                          <p style={{ color: roi < 0 ? '#B33B3B' : '#769358' }}>
                            {roi.toFixed(2)}%
                          </p>
                        );
                      })()}
                    </td>
                    <td id="estSalesTt" className="data-row" style={{ width: "7vw", maxWidth: "6vw" }}>
                      {product.keepa_monthly_sold > 0 ? (
                        <>
                          Verified
                          <br />
                          (by <span style={{ borderRadius: "30px", backgroundColor: "orange", padding: "4% 10%", fontSize: "80%" }}>AMZ</span>):<br />{product.keepa_monthly_sold}+
                        </>
                      ) : product.estimate_sales_30_days}
                    </td>
                    <td id="actionsTt" className="data-row">
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            maxHeight: 48 * 4.5,
                            // width: '20ch',
                          },
                        }}
                      >
                        <MenuItem onClick={() => { setSearchModal(product); setOpenSearchModal(true); handleClose(); }}>
                          <Tooltip title="Search in other suppliers">
                            <SearchSharpIcon />
                          </Tooltip>
                          <Typography>Search in other suppliers</Typography>
                        </MenuItem>
                        <MenuItem onClick={() => {
                          favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? removeFromFavorites(product) : addToFavorites(product);
                          handleClose();
                        }}>
                          <Tooltip title={favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? "Remove from Favorites" : "Add to Favorites"}>
                            {favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                          </Tooltip>
                          <Typography>{favorites.hasOwnProperty(product.asin) && favorites[product.asin] ? "Remove from Favorites" : "Add to Favorites"}</Typography>
                        </MenuItem>
                        {/* <MenuItem onClick={handleClose}>
                          <Tooltip title="Check if Gated">
                            <LockOpenIcon />
                          </Tooltip>
                          <Typography>Check if Gated</Typography>
                        </MenuItem> */}
                        <MenuItem onClick={() => { window.open(`https://keepa.com/#!product/1-${product.asin.split("/").pop()}`, "_blank"); handleClose(); }}>
                          <Tooltip title="Search in Keepa.com">
                            <img
                              className="graphicon"
                              alt="keepa"
                              src="/assets/keepa.png"
                              style={{ width: "1.2vw", height: "1.2vw", }}
                            />
                          </Tooltip>
                          <Typography>&nbsp;Search in Keepa.com</Typography>
                        </MenuItem>
                      </Menu>
                    </td>
                  </tr>
                </>
              )
            })}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={"14"} style={{ border: "none" }}>
                <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: 15, }}>
                  <Button
                    variant={page === 1 ? "outlined" : "contained"}
                    onClick={() => { setPage(p => p - 1); window.location.href = '#'; }}
                    disabled={page === 1}
                    style={{
                      textTransform: 'none',
                      fontFamily: 'RobotoSlab',
                      color: page === 1 ? '#9e9e9e' : '#FCF8F3',
                      borderRadius: '20px',
                      backgroundColor: page === 1 ? '#FCF8F3' : '#7e3e1b',
                      border: `1px solid ${page === 1 ? '#9e9e9e' : '#FCF8F3'}`,
                    }}
                  >
                    Previous Page
                  </Button>
                  <span>Page {page}</span>
                  <Button
                    variant={((page) * 10) > props.data.length ? "outlined" : "contained"}
                    onClick={() => { setPage(p => p + 1); window.location.href = '#'; }}
                    disabled={((page) * 10) > props.data.length}
                    style={{
                      textTransform: 'none',
                      fontFamily: 'RobotoSlab',
                      color: ((page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3',
                      borderRadius: '20px',
                      backgroundColor: ((page) * 10) > props.data.length ? '#FCF8F3' : '#7e3e1b',
                      border: `1px solid ${((page) * 10) > props.data.length ? '#9e9e9e' : '#FCF8F3'}`,
                    }}
                  >
                    Next Page
                  </Button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <Modal open={openSearchModal} onClose={() => setOpenSearchModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '26vw',
            bgcolor: '#FCF8F3',
            borderRadius: 2,
            border: '4px solid rgba(126, 62, 27, 0.5)',
            boxShadow: 24,
            p: 4,
            outline: 'none',
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            Check in other suppliers
          </Typography>

          <p style={{ fontFamily: 'RobotoSlab', textAlign: 'center', }}>
            A specific ASIN can be found from multiple suppliers in Nepeto. By clicking the Nepeto button, you can compare all prices from different suppliers.
          </p>

          {/* Suppliers buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <img
              className="graphicon"
              alt="ebay"
              src={"/assets/ebay.png"}
              style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
              onClick={() =>
                window.open(searchModal.upc !== undefined ?
                  `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.upc}&_odkw=${searchModal.upc}` :
                  `https://www.ebay.com/sch/i.html?&_nkw=${searchModal.title.split(" | At")[0].slice(0, 40)}&_odkw=${searchModal.title.split(" | At")[0].slice(0, 40)}`,
                  "_blank"
                )
              }
            />

            <img
              className="graphicon"
              alt="nepeto"
              src={"/assets/nepeto.png"}
              style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
              onClick={() =>
                window.open(`https://nepeto.com/retail?asin=${searchModal.asin.split("/")[searchModal.asin.split("/").length - 1]}`, "_blank")
              }
            />

            <img
              className="graphicon"
              alt="google"
              src={"https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/250px-Google_2015_logo.svg.png"}
              style={{ width: "5vw", marginTop: "10px", height: "3vw", borderRadius: "20px", border: "2px solid gray", padding: "0.7vw", objectFit: "contain", cursor: "pointer", }}
              onClick={() =>
                window.open(`https://www.google.com/search?q=${searchModal.title.split(" | At")[0]}`, "_blank")
              }
            />
          </Box>

          {/* Cancel button */}
          <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 3 }}>
            <Button variant="contained" onClick={() => setOpenSearchModal(false)} style={{
              textTransform: 'none',
              fontFamily: 'RobotoSlab',
              color: '#FCF8F3',
              borderRadius: '20px',
              backgroundColor: '#B33B3B',
              border: '1px solid #FCF8F3'
            }} >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ProductsTable;